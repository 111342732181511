import feedbacksApi from 'api/feedbacks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { scrollUpIcon } from 'assets/icons';
import { Button, Input, TextArea } from 'components';
import AutoSelect, { OptionListsType } from 'components/AutoSelect';
import StepHeader from 'components/StepHeader';
import getSymbolFromCurrency from 'currency-symbol-map';
import { MemberDto, Status } from 'models/member';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { checkInActions } from 'redux/reducers/checkIn';
import { placeActions } from 'redux/reducers/place';
import { profileActions } from 'redux/reducers/profile';
import {
  selectCheckInError,
  selectCheckInLoading,
  selectSearchHosts,
} from 'redux/selectors/checkIn';
import { selectPlace } from 'redux/selectors/place';
import { selectDailyPass, selectProfile } from 'redux/selectors/profile';
import { CheckInConfirmRequest, SubscriptionReccuringInterval } from 'types';
import { PLACE_ID } from 'utils/storageKeys';

const ProcessCheckIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onSubmitted = useRef(false);
  const [showAdditionalRequirements, setShowAdditionalRequirements] =
    useState(true);
  const [membershipUpgradeRequestVisible, setMembershipUpgradeRequestVisible] =
    useState(false);
  const [membershipUpgradeRequest, setMembershipUpgradeRequest] = useState('');
  const [invitedBy, setInvitedBy] = useState('');
  const [searchHostsQuery, setSeachHostsQuery] = useState('');

  const userProfile = useAppSelector(selectProfile);
  const placeData = useAppSelector(selectPlace);

  const dailyPassData = useAppSelector(selectDailyPass);
  const hostsList = useAppSelector(selectSearchHosts);

  const confirmCheckInError = useAppSelector(selectCheckInError);
  const checkinLoading = useAppSelector(selectCheckInLoading);

  const isHostRequired = userProfile?.status !== Status.ACTIVE;

  const placeIdFromStorage = sessionStorage.getItem(PLACE_ID);

  const subscriptionSettings = placeData?.subscriptionSettings?.find(
    (s) => s.subscriptionId === userProfile?.subscription?.productId
  );

  const dailyPassSettings = placeData?.dailyPassSettings;

  const placeId = placeIdFromStorage ? placeIdFromStorage : placeData?._id;

  useEffect(() => {
    if (!userProfile) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (confirmCheckInError) {
      onSubmitted.current = false;
    }
  }, [confirmCheckInError]);

  useEffect(() => {
    if (userProfile && placeId) {
      dispatch(
        placeActions.getPlaceByIdWithReservationCount({
          userId: userProfile._id,
          placeId: placeId,
        })
      );
      dispatch(
        checkInActions.getReservationByUserAndPlace({
          userId: userProfile?._id,
          placeId: placeId,
        })
      );
      dispatch(
        profileActions.getDailyPass({
          userId: userProfile?._id,
          placeId: placeId,
        })
      );
    }
  }, [placeId, userProfile]);

  useEffect(() => {
    if (searchHostsQuery) {
      dispatch(checkInActions.searchHosts({ lastName: searchHostsQuery }));
    }
  }, [searchHostsQuery]);

  const submitCheckin = (
    activateDailyPass: boolean,
    experienceOnly: boolean
  ): void => {
    if (!onSubmitted.current) {
      onSubmitted.current = true;
      if (!placeId || !userProfile) return;

      let result: CheckInConfirmRequest = {
        userId: userProfile._id,
        placeId: placeId,
        activateDailyPass,
        experienceOnly,
      };

      if (isHostRequired && !experienceOnly) {
        result.invitedBy = invitedBy;
      }

      try {
        dispatch(checkInActions.confirmCheckIn(result));
      } catch {
        onSubmitted.current = false;
      }
    }
  };

  const handleBack = () => {
    if (membershipUpgradeRequestVisible) {
      setMembershipUpgradeRequestVisible(false);
      setShowAdditionalRequirements(true);
    } else {
      navigate('/login');
    }
  };

  const onSubmitMembershipRequest = async () => {
    if (membershipUpgradeRequest.trim().length === 0) return;

    try {
      await feedbacksApi.sendUpgradeMembershipRequest({
        content: membershipUpgradeRequest,
        type: 'request',
        userEmail: (userProfile as MemberDto).email,
      });
      setMembershipUpgradeRequest('');

      toast.success('Request sent');
    } catch (error) {
      toast.error('Error');
    }
  };

  const renderSubmitMembershipUpgradeOption = () => {
    return (
      <>
        <div>
          <TextArea
            id={'membershipUpgradeRequest'}
            name={'membershipUpgradeRequest'}
            label={'MESSAGE TO YAYEM'}
            value={membershipUpgradeRequest || ''}
            onChange={(text) => setMembershipUpgradeRequest(text.target.value)}
          />
        </div>
        <div>
          <Button
            className="confirm-checkin-button"
            type={'button'}
            isLoading={checkinLoading}
            text={'Submit Request'}
            onClick={onSubmitMembershipRequest}
          />
        </div>
      </>
    );
  };

  const renderUpgradeMembershipOption = () => {
    return (
      <>
        <div className="checkin-process-container">
          <div className="checkin-process-title-container">
            <span className="checkin-process-title-text">{`Upgrade`}</span>
          </div>
          <div className="checkin-process-description-container">
            <span className="checkin-process-description-text">
              {`Your membership type does not include access to this space.`}
            </span>
          </div>
          {renderUpgradeMembershipButton()}
        </div>
      </>
    );
  };

  const renderUpgradeMembershipButton = () => {
    return (
      <div style={{ display: 'flex', marginBottom: 30 }}>
        <button
          type={'button'}
          className="upgrade-membership-button"
          onClick={() => {
            setMembershipUpgradeRequestVisible(true);
            setShowAdditionalRequirements(false);
          }}
        >
          <img src={scrollUpIcon} className="upgrade-membership-image" />
          <span>Upgrade your membership</span>
        </button>
      </div>
    );
  };

  const renderViewBasedOnSubscriptionVisitsRestrictionType = () => {
    if (!subscriptionSettings || !placeData) {
      return;
    }

    const usersVisitsCountRemaining =
      getPlaceVisitsRestrictionBasedOnSubscriptionInterval(
        userProfile?.subscription?.interval,
        subscriptionSettings?.visitsRestrictionAmount || 0
      ) - (placeData?.reservationsCount || 0);

    switch (subscriptionSettings.visitsRestrictionType) {
      case 'none':
        return renderUpgradeMembershipOption();
      case 'specific_amount':
        return usersVisitsCountRemaining > 0
          ? renderSpecifcAmountPassedRestriction()
          : dailyPassSettings?.dailyPassAllowed
          ? renderDailyPassAllowedOption()
          : renderDailyPassDisabledOption();
      default:
        return renderUpgradeMembershipOption();
    }
  };

  const getPlaceVisitsRestrictionBasedOnSubscriptionInterval = (
    subscriptionInterval: SubscriptionReccuringInterval | undefined,
    visitsRestrictionAmount: number
  ) => {
    switch (subscriptionInterval) {
      case 'month':
        return Math.round((visitsRestrictionAmount || 0) / 12);
      case 'year':
        return visitsRestrictionAmount || 0;
      default:
        return 0;
    }
  };

  const visitsRestrictionAmount =
    getPlaceVisitsRestrictionBasedOnSubscriptionInterval(
      userProfile?.subscription?.interval,
      subscriptionSettings?.visitsRestrictionAmount || 0
    );
  const usersVisitsCountRemaining =
    getPlaceVisitsRestrictionBasedOnSubscriptionInterval(
      userProfile?.subscription?.interval,
      subscriptionSettings?.visitsRestrictionAmount || 0
    ) - (placeData?.reservationsCount || 0);

  const renderSpecifcAmountPassedRestriction = () => {
    return (
      <>
        <div className="checkin-process-container">
          <div className="checkin-process-title-container">
            <span className="checkin-process-title-text1">
              {`${usersVisitsCountRemaining}`}
            </span>
            <span className="checkin-process-title-text2">
              {`/${visitsRestrictionAmount}`}
            </span>
          </div>
          <div className="checkin-process-description-container">
            <span className="checkin-process-description-text">
              <span>{`Your membership includes `}</span>
              <span className="dia-bold">{`${visitsRestrictionAmount}`}</span>
              <span>{` visits to this space each calendar ${userProfile?.subscription.interval}. After this reservation you would have `}</span>
              <span className="dia-bold">{usersVisitsCountRemaining - 1}</span>
              <span>
                {' '}
                {`visits remaining for the ${userProfile?.subscription.interval}.`}
              </span>
            </span>
          </div>
          {renderUpgradeMembershipButton()}
        </div>
        <div>
          <Button
            className="confirm-checkin-button"
            isLoading={checkinLoading}
            type={'button'}
            text={'Confirm Reservation & Check In'}
            onClick={() => submitCheckin(false, false)}
          />
        </div>
      </>
    );
  };

  const renderDailyPassAllowedOption = () => {
    return (
      <>
        <div className="checkin-process-container">
          <div className="checkin-process-title-container">
            <span className="checkin-process-title-text1">
              {`${getSymbolFromCurrency(
                dailyPassSettings?.currency as string
              )}${
                dailyPassData ? 0 : (dailyPassSettings?.price as number) / 100
              }`}
            </span>
          </div>
          <div className="checkin-process-description-container">
            <span className="checkin-process-description-text">
              <span>
                {dailyPassData
                  ? `You have used all of your allocated visits and you already have daily pass for this place. You can use it to complete this reservation.`
                  : `You have used all of your allocated visits.  You can purchase a day pass to complete this reservation.`}
              </span>
            </span>
          </div>
          {renderUpgradeMembershipButton()}
        </div>
        <div>
          <Button
            className="confirm-checkin-button"
            isLoading={checkinLoading}
            type={'button'}
            text={'Confirm Reservation & Check In'}
            onClick={() => submitCheckin(true, false)}
          />
        </div>
      </>
    );
  };

  const renderDailyPassDisabledOption = () => {
    return (
      <>
        <div className="checkin-process-container">
          <div className="checkin-process-title-container">
            <span className="checkin-process-title-text">{`Upgrade`}</span>
          </div>
          <div className="checkin-process-description-container">
            <span className="checkin-process-description-text">{`You have used all of your allocated visits`}</span>
          </div>
          {renderUpgradeMembershipButton()}
        </div>
      </>
    );
  };

  const renderGuestCheckin = () => {
    return (
      <div>
        <Input
          id={'invitedBy'}
          name={'invitedBy'}
          type={'text'}
          label={'Host email'}
          value={invitedBy}
          onChange={(text) => setInvitedBy(text.target.value)}
          isError={Boolean(confirmCheckInError)}
          errorText={confirmCheckInError}
        />
        <div className="check-in-container">
          <span className="span-or">OR</span>
        </div>
        <AutoSelect
          id={'searchHostsQuery'}
          name={'searchHostsQuery'}
          type={'single'}
          options={hostsList.map((x) => ({
            key: x.email,
            value: x.profile.firstName as string,
          }))}
          searchValue={searchHostsQuery}
          label={'Host Last Name'}
          onSelect={function (value: OptionListsType): void {
            setInvitedBy(value.key);
            setSeachHostsQuery(
              hostsList.find((h) => h.email === value.key)?.profile.lastName ||
                ''
            );
          }}
          onValueChange={function (value: string): void {
            setSeachHostsQuery(value);
          }}
        />
        <Button
          className="confirm-checkin-button"
          isLoading={checkinLoading}
          type={'button'}
          text={'Confirm Reservation & Check In'}
          onClick={() => submitCheckin(false, false)}
        />
      </div>
    );
  };

  return (
    <div>
      <StepHeader current={2} total={2} prev={handleBack} />
      <div className="page check-in-page">
        <div className="container">
          {isHostRequired ? (
            <>
              <div className="check-in-container">
                <span className="register-header">
                  Whom are you visiting today?
                </span>
              </div>
              {renderGuestCheckin()}
            </>
          ) : (
            <div style={{ marginTop: 32 }}>
              <Input
                id={'email'}
                name={'email'}
                type={'text'}
                label={'Email'}
                value={userProfile?.email}
                onChange={() => {}}
                disabled={true}
              />
              {showAdditionalRequirements ? (
                renderViewBasedOnSubscriptionVisitsRestrictionType()
              ) : membershipUpgradeRequestVisible ? (
                renderSubmitMembershipUpgradeOption()
              ) : (
                <div></div>
              )}
            </div>
          )}
          <div className="check-in-container">
            <a
              onClick={() => submitCheckin(false, true)}
              target="_blank"
              rel="noreferrer"
              className="learn-more-button"
            >
              I'm here for an event
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessCheckIn;
