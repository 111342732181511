import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MemberDto } from 'models/member';
import {
  CheckInConfirmRequest,
  CheckInConfirmResponse,
  GetReservationByUserAndPlaceRequest,
  ReservationResponse,
  SearchHostsRequest,
} from 'types';

export interface CheckInState {
  loading: boolean;
  reservation: ReservationResponse | null;
  hosts: MemberDto[];
  error: any;
}

const initialState: CheckInState = {
  loading: false,
  error: null,
  reservation: null,
  hosts: [],
};

const checkInSlice = createSlice({
  name: 'checkIn',
  initialState: initialState,
  reducers: {
    confirmCheckIn(
      state: CheckInState,
      action: PayloadAction<CheckInConfirmRequest>
    ) {
      state.loading = true;
    },
    confirmCheckInSuccess(
      state: CheckInState,
      action: PayloadAction<ReservationResponse>
    ) {
      state.loading = false;
      state.reservation = action.payload;
    },
    confirmCheckInFailed(state: CheckInState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getReservationById(state: CheckInState, action: PayloadAction<string>) {
      state.loading = true;
    },
    getReservationByIdSuccess(
      state: CheckInState,
      action: PayloadAction<ReservationResponse>
    ) {
      state.loading = false;
      state.reservation = action.payload;
    },
    getReservationByIdFailed(state: CheckInState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getReservationByUserAndPlace(
      state: CheckInState,
      action: PayloadAction<GetReservationByUserAndPlaceRequest>
    ) {
      state.loading = true;
    },
    getReservationByUserAndPlaceSuccess(
      state: CheckInState,
      action: PayloadAction<ReservationResponse>
    ) {
      state.loading = false;
      state.reservation = action.payload;
    },
    getReservationByUserAndPlaceFailed(
      state: CheckInState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
    searchHosts(
      state: CheckInState,
      action: PayloadAction<SearchHostsRequest>
    ) {
      state.loading = true;
    },
    searchHostsSuccess(
      state: CheckInState,
      action: PayloadAction<MemberDto[]>
    ) {
      state.loading = false;
      state.hosts = action.payload;
    },
    searchHostsFailed(state: CheckInState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const checkInActions = checkInSlice.actions;

// Reducer
const checkInReducer = checkInSlice.reducer;
export default checkInReducer;
