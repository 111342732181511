import {
  PlaceByIdResponse,
  PlaceByIdRequest,
  GetPlaceWithReservationsCountRequest,
} from 'types';
import publicAxiosApi from './config';

const placeApi = {
  getPlaceById({ id }: PlaceByIdRequest): Promise<PlaceByIdResponse> {
    const url = `api/check-in/places/${id}`;
    return publicAxiosApi.get(url);
  },

  getPlaceWithReservationsCount(
    data: GetPlaceWithReservationsCountRequest
  ): Promise<PlaceByIdResponse> {
    const url = `api/check-in/places/${data.placeId}/reservations-count?userId=${data.userId}`;
    return publicAxiosApi.get(url);
  },
};

export default placeApi;
