import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, FrontBanner, Header, Input } from 'components';
import { FormikHelpers, useFormik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { authActions } from 'redux/reducers/auth';
import { selectAuthError, selectAuthLoading } from 'redux/selectors/auth';
import {
  selectCheckInLoading,
  selectReservationData,
} from 'redux/selectors/checkIn';
import { selectPlace, selectPlaceLoading } from 'redux/selectors/place';
import { selectProfileLoading } from 'redux/selectors/profile';
import { PLACE_ID } from 'utils/storageKeys';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Required'),
});

export interface LoginValuesModel {
  email: string;
}

const Login = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const placeData = useAppSelector(selectPlace);
  const confirmLoginError = useAppSelector(selectAuthError);
  const authLoading = useAppSelector(selectAuthLoading);
  const placeLoading = useAppSelector(selectPlaceLoading);
  const reservationLoading = useAppSelector(selectCheckInLoading);
  const profileLoading = useAppSelector(selectProfileLoading);

  const placeIdFromStorage = sessionStorage.getItem(PLACE_ID);

  const submitHandler = useCallback(
    (values: LoginValuesModel): void => {
      let placeId = placeIdFromStorage ? placeIdFromStorage : placeData?._id;
      if (!placeId) {
        return;
      }
      dispatch(
        authActions.login({
          email: values.email,
          placeId,
        })
      );
    },
    [dispatch, navigate]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
  });

  const onChangeHandler = (value: string) => {
    // replace the masked value
    formik.setFieldValue('email', value);

    if (confirmLoginError) {
      dispatch(authActions.loginFailed(null));
    }
  };

  return (
    <div>
      <Header withBackArrow={false} />
      <form className="page" onSubmit={formik.handleSubmit} autoComplete="on">
        <div className="login-container container">
          <FrontBanner mainText={'Welcome'} placeName={placeData?.name || ''} />
          <Input
            id={'email'}
            name={'email'}
            type={'text'}
            label={'Email'}
            value={formik.values.email}
            autoFocus
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
            isError={
              formik.touched.email &&
              (Boolean(formik.errors.email) || Boolean(confirmLoginError))
            }
            errorText={formik.errors.email || confirmLoginError}
          />
          <Button
            type={'submit'}
            text={'Check In'}
            isLoading={
              authLoading ||
              placeLoading ||
              profileLoading ||
              reservationLoading
            }
          />
          <a
            href="https://yayem.co/"
            target="_blank"
            rel="noreferrer"
            className="learn-more-button"
          >
            Explore Yayem
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
