import { PayloadAction } from '@reduxjs/toolkit';
import history from 'utils/route-history';
import placeApi from 'api/place';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { placeActions } from 'redux/reducers/place';
import {
  GetPlaceWithReservationsCountRequest,
  PlaceByIdRequest,
  PlaceByIdResponse,
} from 'types';

function* getPlaceById(data: PayloadAction<PlaceByIdRequest>) {
  try {
    const response: PlaceByIdResponse = yield call(
      placeApi.getPlaceById,
      data.payload
    );
    yield put(placeActions.getPlaceByIdSuccess(response));
    history.push('/login');
  } catch (error) {
    console.log(`Failed to confirm check-in`, error);
    history.push('/place-not-found');
    if (axios.isAxiosError(error)) {
      yield put(placeActions.getPlaceByIdFailed(error.response?.data.message));
    }
  }
}

export function* getPlaceByIdWithReservationCount(
  data: PayloadAction<GetPlaceWithReservationsCountRequest>
) {
  try {
    const response: PlaceByIdResponse = yield call(
      placeApi.getPlaceWithReservationsCount,
      data.payload
    );
    yield put(placeActions.getPlaceByIdWithReservationCountSuccess(response));
  } catch (error) {
    console.log(`Failed to confirm check-in`, error);
    history.push('/place-not-found');
    if (axios.isAxiosError(error)) {
      yield put(
        placeActions.getPlaceByIdWithReservationCountFailed(
          error.response?.data.message
        )
      );
    }
  }
}

export default function* placeSaga() {
  yield takeLatest(placeActions.getPlaceById.type, getPlaceById);
  yield takeLatest(
    placeActions.getPlaceByIdWithReservationCount.type,
    getPlaceByIdWithReservationCount
  );
}
