import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from 'redux/reducers/auth';
import profileReducer from 'redux/reducers/profile';
import checkInReducer from 'redux/reducers/checkIn';
import placeReducer from 'redux/reducers/place';

import rootSaga from './rootSaga';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  checkIn: checkInReducer,
  place: placeReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
