import React from 'react';

interface Props {
  mainText: string;
  placeName?: string;
  image?: string;
  className?: string;
}

const FrontBanner: React.FC<Props> = ({
  mainText,
  placeName,
  image,
  className,
}) => {
  const bannerClassName = className ?? '';

  return (
    <div className={`banner-container ${bannerClassName}`}>
      {image && <img src={image} alt="Login"></img>}
      <div className="banner-text-container">
        <span className="beaufortPro-normal banner-main-text">{mainText}</span>
        {placeName && (
          <span className="dia-normal banner-subtitle">{placeName}</span>
        )}
      </div>
    </div>
  );
};

export default FrontBanner;
