import { useAppSelector } from 'app/hooks';
import { isProfileEmpty } from 'helpers/profile';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectProfile } from 'redux/selectors/profile';

const Layout = (): JSX.Element => {
  const navigate = useNavigate();
  const userProfile = useAppSelector(selectProfile);

  const isProfileMainDataEmpty = isProfileEmpty(userProfile?.profile);

  const redirectTo = useCallback(() => {
    if (!userProfile) {
      navigate('/login');
      return;
    }

    if (isProfileMainDataEmpty) {
      navigate('/basic-info');
      return;
    } else {
      navigate('/process-check-in');
      return;
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    redirectTo();
  }, [navigate, redirectTo]);

  return <div></div>;
};

export default Layout;
