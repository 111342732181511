import { FrontBanner, Header } from 'components';

const PlaceNotFound = (): JSX.Element => {
  return (
    <>
      <Header withBackArrow={false} />
      <div className="page">
        <div className="not-found-container container">
          <FrontBanner
            mainText={'Welcome'}
            placeName={'SCAN QR CODE TO BEGIN CHECK-IN'}
          />
        </div>
      </div>
    </>
  );
};

export default PlaceNotFound;
