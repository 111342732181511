import { useSearchParams } from 'react-router-dom';
import { PLACE_ID } from './storageKeys';

export default function useHandleSearchParams() {
  const [searchParams] = useSearchParams();

  const placeId = searchParams.get('placeId');
  if (placeId) {
    sessionStorage.setItem(PLACE_ID, placeId);
  }
}
