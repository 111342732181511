import { backArrowIcon } from 'assets/icons';
import { useMemo } from 'react';
interface Props {
  current: number;
  total: number;
  prev?: () => void;
}

const StepHeader: React.FC<Props> = ({ current, total, prev }) => {
  const onboardingCompletePercentage = useMemo(
    () => (current / total) * 100,
    [current, total]
  );

  return (
    <div className="step-header">
      <div className="step-header-status__container">
        {prev ? (
          <span onClick={prev} className="step-arrow-back">
            <img src={backArrowIcon} alt="Back" />
          </span>
        ) : (
          <span />
        )}
        <div
          className="step-header-status__current_steps"
          style={{ width: `${onboardingCompletePercentage}%` }}
        />
        <div
          className="step-header-status__upcoming_steps"
          style={{ width: `${100 - onboardingCompletePercentage}%` }}
        />
        <span className="step-header-status__indicator">
          {current} / {total}
        </span>
      </div>
    </div>
  );
};

export default StepHeader;
