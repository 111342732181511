import React from 'react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import MainRouter from 'routes';
import HistoryRouter from 'routes/HistoryRouter';
import history from 'utils/route-history';
import 'react-toastify/dist/ReactToastify.css';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_PROJECT_ID as string);

const App = () => {
  return (
    <HistoryRouter history={history}>
      <MainRouter />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </HistoryRouter>
  );
};

export default App;
