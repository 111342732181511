import { MemberDto } from 'models/member';
import {
  CheckInConfirmRequest,
  CreateCheckoutSessionResponse,
  GetReservationByUserAndPlaceRequest,
  ReservationResponse,
  SearchHostsRequest,
} from 'types';
import axiosApi from './config';

const checkInApi = {
  confirmCheckIn(
    data: CheckInConfirmRequest
  ): Promise<ReservationResponse | CreateCheckoutSessionResponse> {
    const url = 'api/check-in/confirm';
    return axiosApi.post(url, data);
  },
  getReservationById(reservationId: string): Promise<ReservationResponse> {
    const url = `api/check-in/reservations/${reservationId}`;
    return axiosApi.get(url);
  },
  getReservationByUserAndPlace(
    data: GetReservationByUserAndPlaceRequest
  ): Promise<ReservationResponse> {
    const url = `api/check-in/reservations?userId=${data.userId}&placeId=${data.placeId}`;
    return axiosApi.get(url);
  },

  searchHosts(data: SearchHostsRequest): Promise<MemberDto[]> {
    const url = `api/check-in/hosts/search?lastName=${data.lastName}`;
    return axiosApi.get(url);
  },
};

export default checkInApi;
