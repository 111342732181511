import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DailyPassDto, MemberDto, MemberProfile } from 'models/member';
import { GetDailyPassRequest } from 'types';

export interface ProfileState {
  loading: boolean;
  data: MemberDto | null;
  dailyPass: DailyPassDto | null;
  error: any;
}

const initialState: ProfileState = {
  loading: false,
  data: null,
  dailyPass: null,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    getProfile(state: ProfileState, action: PayloadAction<string>) {
      state.loading = true;
    },
    getProfileSuccess(state: ProfileState, action: PayloadAction<MemberDto>) {
      state.loading = false;
      state.data = action.payload;
    },
    getProfileFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfile(
      state: ProfileState,
      action: PayloadAction<Partial<MemberProfile>>
    ) {
      state.loading = true;
    },
    updateProfileSuccess(
      state: ProfileState,
      action: PayloadAction<Partial<MemberProfile>>
    ) {
      state.loading = false;
      const data = state.data;
      if (data) {
        data.profile = { ...data.profile, ...action.payload };
      }
      state.data = data;
    },
    updateProfileFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getDailyPass(
      state: ProfileState,
      action: PayloadAction<GetDailyPassRequest>
    ) {
      state.loading = true;
    },
    getDailyPassSuccess(
      state: ProfileState,
      action: PayloadAction<DailyPassDto>
    ) {
      state.loading = false;
      state.dailyPass = action.payload;
    },
    getDailyPassFailed(state: ProfileState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const profileActions = profileSlice.actions;

// Reducer
const profileReducer = profileSlice.reducer;
export default profileReducer;
