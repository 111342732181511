import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonUserDto } from 'models/member';
import { LoginMemberRequest, LoginMemberResponse } from 'types';

export interface AuthState {
  loading: boolean;
  //accessToken: string | null;
  error: any;
}

const initialState: AuthState = {
  loading: false,
  //accessToken: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login(state: AuthState, action: PayloadAction<LoginMemberRequest>) {
      state.loading = true;
    },
    loginSuccess(state: AuthState) {
      state.loading = false;
      // state.accessToken = action.payload.accessToken;
    },
    loginFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
