import { PayloadAction } from '@reduxjs/toolkit';
import authApi from 'api/auth';
import axios from 'axios';
import { DailyPassDto, MemberDto, Status } from 'models/member';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { authActions } from 'redux/reducers/auth';
import {
  CheckInConfirmRequest,
  GetDailyPassRequest,
  GetPlaceWithReservationsCountRequest,
  GetReservationByUserAndPlaceRequest,
  LoginMemberRequest,
  ReservationResponse,
} from 'types';
import history from 'utils/route-history';
import { PLACE_ID } from 'utils/storageKeys';
import { checkInActions } from '../redux/reducers/checkIn';
import { profileActions } from '../redux/reducers/profile';
import { placeActions } from '../redux/reducers/place';
import { selectPlace } from '../redux/selectors/place';
import { selectDailyPass } from '../redux/selectors/profile';
import { selectReservationData } from '../redux/selectors/checkIn';
import { getPlaceByIdWithReservationCount } from './placeSaga';
import { getReservationByUserAndPlace, confirmCheckIn } from './checkInSaga';
import { getDailyPass } from './profileSaga';

import { PlaceDto } from 'models/place';
import { isProfileEmpty } from 'helpers/profile';

function* login(data: PayloadAction<LoginMemberRequest>) {
  try {
    if (!data.payload.placeId) {
      yield put(
        authActions.loginFailed(
          'Error occured when submitting the check-in. Please reload the page and try again.'
        )
      );
      return;
    }
    const member: MemberDto = yield call(authApi.login, data.payload);

    yield put(profileActions.getProfileSuccess(member));

    const placeId = sessionStorage.getItem(PLACE_ID);

    yield all([
      call(getReservationByUserAndPlace, {
        payload: { userId: member._id, placeId },
        type: 'checkIn/getReservationByUserAndPlace',
      } as PayloadAction<GetReservationByUserAndPlaceRequest>),
      call(getDailyPass, {
        payload: {
          userId: member._id,
          placeId,
        },
        type: 'profile/getDailyPass',
      } as PayloadAction<GetDailyPassRequest>),
      call(getPlaceByIdWithReservationCount, {
        payload: { userId: member._id, placeId },
        type: 'place/getPlaceByIdWithReservationCount',
      } as PayloadAction<GetPlaceWithReservationsCountRequest>),
    ]);

    yield put(authActions.loginSuccess());

    if (isProfileEmpty(member?.profile)) {
      history.push('/basic-info');
    } else {
      const place: PlaceDto = yield select(selectPlace);
      const dailyPass: DailyPassDto = yield select(selectDailyPass);
      const reservation: ReservationResponse = yield select(
        selectReservationData
      );

      if (reservation?.checkedIn) {
        history.push('/success');
      } else {
        const subscriptionSettings = place?.subscriptionSettings?.find(
          (s) => s.subscriptionId === member?.subscription?.productId
        );

        if (
          member &&
          (reservation ||
            subscriptionSettings?.visitsRestrictionType === 'unlimited' ||
            (subscriptionSettings?.visitsRestrictionType ===
              'specific_amount' &&
              dailyPass))
        ) {
          yield call(confirmCheckIn, {
            payload: {
              userId: member._id,
              placeId,
              activateDailyPass: false,
              experienceOnly: false,
            },
            type: 'checkIn/confirmCheckIn',
          } as PayloadAction<CheckInConfirmRequest>);
        } else {
          history.push('/process-check-in');
        }
      }
    }
  } catch (error) {
    console.log(`Failed to login`, error);
    if (axios.isAxiosError(error)) {
      yield put(authActions.loginFailed(error.response?.data.message));
    }
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.login.type, login);
}
