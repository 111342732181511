import { SendUpgradeMembershipRequest } from 'types';
import axiosApi from './config';

const feedbacksApi = {
  sendUpgradeMembershipRequest(data: SendUpgradeMembershipRequest) {
    const url = `api/check-in/feedbacks`;
    return axiosApi.post(url, data);
  },
};

export default feedbacksApi;
