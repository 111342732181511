import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PlaceByIdResponse,
  PlaceByIdRequest,
  GetPlaceWithReservationsCountRequest,
} from 'types';

export interface PlaceState {
  loading: boolean;
  placeData: PlaceByIdResponse | null;
  error: any;
}

const initialState: PlaceState = {
  loading: false,
  error: null,
  placeData: null,
};

const placeSlice = createSlice({
  name: 'place',
  initialState: initialState,
  reducers: {
    getPlaceById(state: PlaceState, action: PayloadAction<PlaceByIdRequest>) {
      state.loading = true;
    },
    getPlaceByIdSuccess(
      state: PlaceState,
      action: PayloadAction<PlaceByIdResponse>
    ) {
      state.loading = false;
      state.placeData = action.payload;
    },
    getPlaceByIdFailed(state: PlaceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getPlaceByIdWithReservationCount(
      state: PlaceState,
      action: PayloadAction<GetPlaceWithReservationsCountRequest>
    ) {
      state.loading = true;
    },
    getPlaceByIdWithReservationCountSuccess(
      state: PlaceState,
      action: PayloadAction<PlaceByIdResponse>
    ) {
      state.loading = false;
      state.placeData = action.payload;
    },
    getPlaceByIdWithReservationCountFailed(
      state: PlaceState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const placeActions = placeSlice.actions;

// Reducer
const placeReducer = placeSlice.reducer;
export default placeReducer;
