import { LoginMemberRequest, LoginMemberResponse } from 'types';
import { publicAxiosApi } from './config';

const authApi = {
  login(data: LoginMemberRequest): Promise<LoginMemberResponse> {
    const url = 'api/check-in/auth/confirm-login';
    return publicAxiosApi.post(url, data);
  },
};

export default authApi;
