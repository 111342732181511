import React, { useState } from 'react';
import { checkedIcon } from 'assets/icons';

interface Props {
  title: string;
  subTitle: string;
  defaultValue: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  name: string;
  id: string;
}

const Checkbox: React.FC<Props> = ({ title, subTitle, defaultValue, className, onChange }) => {
  const [isChecked, setIsChecked] = useState(defaultValue);
  const customClassName = className ?? '';

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked)
  };

  return (
    <div className={`checkbox-wrapper ${customClassName}`}>
      <label>
        <input type="checkbox" onChange={handleOnChange} checked={isChecked} />
        <div className={`indicator-wrap ${isChecked ? 'checked' : ''}`}>
          {isChecked && <img src={checkedIcon} className="indicator" />}
        </div>
        <div className="label-wrap">
          <span className="dia-thin checkbox-label">{title}</span>
          <span className="beaufortPro-thin checkbox-label">{subTitle}</span>
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
