import { PayloadAction } from '@reduxjs/toolkit';
import profileApi from 'api/profile';
import axios from 'axios';
import { DailyPassDto, MemberDto, MemberProfile } from 'models/member';
import { call, put, takeLatest } from 'redux-saga/effects';
import { profileActions } from 'redux/reducers/profile';
import { GetDailyPassRequest } from 'types';

function* getProfile(data: PayloadAction<string>) {
  try {
    const response: MemberDto = yield call(profileApi.getProfile, data.payload);
    yield put(profileActions.getProfileSuccess(response));
  } catch (error) {
    console.log(`Failed to get member profile`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.getProfileFailed(error.message));
    }
  }
}

function* updateProfile(data: PayloadAction<MemberProfile>) {
  try {
    yield call(profileApi.updateProfile, data.payload);
    yield put(profileActions.updateProfileSuccess(data.payload));
  } catch (error) {
    console.log(`Failed to update member profile`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.updateProfileFailed(error.message));
    }
  }
}

export function* getDailyPass(data: PayloadAction<GetDailyPassRequest>) {
  try {
    const response: DailyPassDto = yield call(
      profileApi.getDailyPass,
      data.payload
    );
    yield put(profileActions.getDailyPassSuccess(response));
  } catch (error) {
    console.log(`Failed to get daily pass`, error);
    if (axios.isAxiosError(error)) {
      yield put(profileActions.getDailyPassFailed(error.message));
    }
  }
}

export default function* profileSaga() {
  yield takeLatest(profileActions.getProfile.type, getProfile);
  yield takeLatest(profileActions.updateProfile.type, updateProfile);
  yield takeLatest(profileActions.getDailyPass.type, getDailyPass);
}
