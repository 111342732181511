import React, { FC } from 'react';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
  color?: string;
  height?: number;
  width?: number;
};

const ReactLoader: FC<Props> = ({
  color = '#4c4ca0',
  height = 50,
  width = 20,
}) => {
  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <ThreeDots color={color} height={width} width={height} />
    </div>
  );
};

export default ReactLoader;
