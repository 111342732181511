import { ReactLoader } from 'components';
import React from 'react';

interface Props {
  type: 'button' | 'submit';
  text: string;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
}

const Button: React.FC<Props> = ({
  text,
  type,
  onClick,
  className,
  isLoading = false,
}) => {
  const customClassName = className ?? '';
  return (
    <div className="button-container">
      <button
        className={`button ${customClassName}`}
        type={type}
        onClick={onClick}
      >
        {isLoading ? (
          <ReactLoader />
        ) : (
          <span className="button-text dia-big">{text}</span>
        )}
      </button>
    </div>
  );
};

export default Button;
