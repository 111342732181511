import { SubscriptionReccuringInterval } from 'types';

export class MemberDto {
  _id: string;
  userType: UserType;
  email: string;
  status: Status;
  createdAt: Date;
  updatedAt: Date | null;
  profile: Partial<MemberProfile>;
  subscription: SubscriptionDto;
}

export type CommonUserDto = {
  _id: string;
  userType: UserType;
  email: string;
  lastLogin: Date;
  createdAt: Date;
  updatedAt: Date | null;
};

export type DailyPassDto = {
  _id: string;
  user: string;
  place: string;
  activeDate: Date;
  createdAt: Date;
};

export type SubscriptionDto = {
  productId: string;
  interval: SubscriptionReccuringInterval;
};

export enum Status {
  GUEST = 'guest',
  NEW = 'new',
  AWAITING_REVIEW = 'awaiting_review',
  APPROVED = 'approved',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  INACTIVE = 'inactive',
  REJECTED = 'rejected',
}

export type MemberProfile = {
  email: string;
  firstName?: string;
  lastName?: string;
  receiveMarketingMessages?: boolean;
  mediaUsagePermission?: boolean;
};

export enum UserType {
  MEMBER = 'member',
  GUEST = 'guest',
}
