import { PayloadAction } from '@reduxjs/toolkit';
import checkInApi from 'api/checkIn';
import axios from 'axios';
import { MemberDto } from 'models/member';
import { call, put, takeLatest } from 'redux-saga/effects';
import { checkInActions } from 'redux/reducers/checkIn';
import {
  CheckInConfirmRequest,
  CreateCheckoutSessionResponse,
  GetReservationByUserAndPlaceRequest,
  ReservationResponse,
  SearchHostsRequest,
} from 'types';
import history from 'utils/route-history';

export function* confirmCheckIn(data: PayloadAction<CheckInConfirmRequest>) {
  try {
    const response: ReservationResponse | CreateCheckoutSessionResponse =
      yield call(checkInApi.confirmCheckIn, data.payload);

    if ((response as CreateCheckoutSessionResponse)?.url) {
      yield call(redirectTo, (response as CreateCheckoutSessionResponse).url);
    } else {
      yield put(
        checkInActions.confirmCheckInSuccess(response as ReservationResponse)
      );
      history.push(`/success`);
    }
  } catch (error) {
    console.log(`Failed to confirm check-in`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        checkInActions.confirmCheckInFailed(error.response?.data.message)
      );
    }
  }
}

function* getReservationById(data: PayloadAction<string>) {
  try {
    const response: ReservationResponse = yield call(
      checkInApi.getReservationById,
      data.payload
    );

    yield put(checkInActions.getReservationByIdSuccess(response));
  } catch (error) {
    console.log(`Failed to get check-in info`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        checkInActions.getReservationByIdFailed(error.response?.data.message)
      );
    }
  }
}

export function* getReservationByUserAndPlace(
  data: PayloadAction<GetReservationByUserAndPlaceRequest>
) {
  try {
    const response: ReservationResponse = yield call(
      checkInApi.getReservationByUserAndPlace,
      data.payload
    );

    yield put(checkInActions.getReservationByUserAndPlaceSuccess(response));
  } catch (error) {
    console.log(`Failed to get check-in info`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        checkInActions.getReservationByUserAndPlaceFailed(
          error.response?.data.message
        )
      );
    }
  }
}

function* searchHosts(data: PayloadAction<SearchHostsRequest>) {
  try {
    const response: MemberDto[] = yield call(
      checkInApi.searchHosts,
      data.payload
    );

    yield put(checkInActions.searchHostsSuccess(response));
  } catch (error) {
    console.log(`Failed to get hosts for checkin`, error);
    if (axios.isAxiosError(error)) {
      yield put(checkInActions.searchHostsFailed(error.response?.data.message));
    }
  }
}

export const redirectTo = (url: string) => (window.location.href = url);

export default function* checkInSaga() {
  yield takeLatest(checkInActions.confirmCheckIn.type, confirmCheckIn);
  yield takeLatest(checkInActions.getReservationById.type, getReservationById);
  yield takeLatest(
    checkInActions.getReservationByUserAndPlace.type,
    getReservationByUserAndPlace
  );
  yield takeLatest(checkInActions.searchHosts.type, searchHosts);
}
