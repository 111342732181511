import { DailyPassDto, MemberDto, MemberProfile } from 'models/member';
import { GetDailyPassRequest } from 'types';
import axiosApi from './config';

const profileApi = {
  getProfile(email: string): Promise<MemberDto> {
    const url = `api/check-in/member/me/profile?email=${email}`;
    return axiosApi.get(url);
  },

  updateProfile(data: MemberProfile): Promise<MemberDto> {
    const url = `api/check-in/member/me/profile/${data.email}`;
    return axiosApi.put(url, data);
  },

  getDailyPass(data: GetDailyPassRequest): Promise<DailyPassDto> {
    const url = `api/check-in/member/me/daily-passes?userId=${data.userId}&placeId=${data.placeId}`;
    return axiosApi.get(url);
  },
};

export default profileApi;
