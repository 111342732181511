import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useHandleSearchParams from 'utils/useHandleSearchParams';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Layout from 'pages/Layout';
import Login from 'pages/Login';
import BasicInfo from 'pages/BasicInfo';
import ProcessCheckIn from 'pages/ProcessCheckIn';
import Success from 'pages/Success';
import PlaceNotFound from 'pages/PlaceNotFound';
import { ACCESS_TOKEN, PLACE_ID } from 'utils/storageKeys';
import { useAppDispatch } from 'app/hooks';
import { profileActions } from 'redux/reducers/profile';
import { placeActions } from 'redux/reducers/place';

const MainRouter = () => {
  const placeId = sessionStorage.getItem(PLACE_ID);
  const navigate = useNavigate();

  useHandleSearchParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/success')) {
      if (placeId) {
        dispatch(placeActions.getPlaceById({ id: placeId }));
        navigate('/');
      } else {
        navigate('/place-not-found');
      }
    }
  }, [placeId, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Layout />} />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/basic-info"
        element={
          <PublicRoute>
            <BasicInfo />
          </PublicRoute>
        }
      />
      <Route
        path="/process-check-in"
        element={
          <PublicRoute>
            <ProcessCheckIn />
          </PublicRoute>
        }
      />
      <Route
        path="/success"
        element={
          <PublicRoute>
            <Success />
          </PublicRoute>
        }
      />
      <Route
        path="/success/:reservationId"
        element={
          <PublicRoute>
            <Success />
          </PublicRoute>
        }
      />
      <Route
        path="/place-not-found"
        element={
          <PublicRoute>
            <PlaceNotFound />
          </PublicRoute>
        }
      />
    </Routes>
  );
};

export default MainRouter;
