import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Checkbox, Header, Input } from 'components';
import LogOutButton from 'components/LogoutButton';
import StepHeader from 'components/StepHeader';
import { useFormik } from 'formik';
import { Status } from 'models/member';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { profileActions } from 'redux/reducers/profile';
import { selectProfile } from 'redux/selectors/profile';
import * as yup from 'yup';

// @ts-ignore
yup.addMethod(yup.string, 'isValidDate', function (args) {
  const { message, maxDate } = args;
  return this.test('isValid', message, function (value, originalValue) {
    const { path, createError } = this;

    if (!moment(value).isValid()) {
      return createError({ message: 'Date is not valid', path });
    }

    return (
      moment(value).isBefore(moment(maxDate)) || createError({ message, path })
    );
  });
});

const validationSchema = yup.object().shape({
  email: yup.string().required('Required'),
  firstName: yup.string()
    .trim()
    .required('Required')
    .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Cannot contain only white spaces'),
  lastName: yup.string()
    .trim()
    .required('Required')
    .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Cannot contain only white spaces'),
});

export interface BasicInfoValuesModel {
  email: string;
  firstName?: string;
  lastName?: string;
  receiveMarketingMessages?: boolean;
  mediaUsagePermission?: boolean;
}

const BasicInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const userProfile = useAppSelector(selectProfile);
  const isGuest = userProfile?.status !== Status.ACTIVE;

  const submitHandler = useCallback(
    (values: BasicInfoValuesModel): void => {
      dispatch(profileActions.updateProfile(values));
      navigate('/process-check-in', {
        state: { hostRequired: userProfile?.status !== Status.ACTIVE },
      });
    },
    [dispatch, navigate]
  );

  const handleBack = () => {
    navigate('/login');
  };

  const formik = useFormik({
    initialValues: {
      email: userProfile?.email || '',
      firstName: userProfile?.profile?.firstName || '',
      lastName: userProfile?.profile?.lastName || '',
      receiveMarketingMessages:
        userProfile?.profile?.receiveMarketingMessages ?? true,
      mediaUsagePermission: userProfile?.profile?.mediaUsagePermission ?? true,
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <div>
      <StepHeader current={1} total={2} prev={handleBack} />
      <div className="page basic-info-page">
        <div className="container basic-info-container">
          <span className="register-header">Register</span>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Input
              id={'email'}
              name={'email'}
              type={'text'}
              label={'Email'}
              value={formik.values.email || ''}
              onChange={formik.handleChange}
              isError={formik.touched.email && Boolean(formik.errors.email)}
              errorText={formik.errors.email}
            />
            <Input
              id={'firstName'}
              name={'firstName'}
              type={'text'}
              label={'First Name'}
              value={formik.values.firstName || ''}
              onChange={formik.handleChange}
              isError={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              errorText={formik.errors.firstName}
            />
            <Input
              id={'lastName'}
              name={'lastName'}
              type={'text'}
              label={'Last Name'}
              value={formik.values.lastName || ''}
              onChange={formik.handleChange}
              isError={
                formik.touched.lastName && Boolean(formik.errors.lastName)
              }
              errorText={formik.errors.lastName}
            />
            {isGuest && (
              <div className="checkbox-wrap">
                <Checkbox
                  id={'receiveMarketingMessages'}
                  name={'receiveMarketingMessages'}
                  title="We want to keep you in the loop!"
                  subTitle="Tick this box to receive marketing messages from us."
                  onChange={(isEnabled) =>
                    formik.setFieldValue('receiveMarketingMessages', isEnabled)
                  }
                  defaultValue={formik.values.receiveMarketingMessages || true}
                  className="dietry-checkbox"
                />
                <Checkbox
                  id={'mediaUsagePermission'}
                  name={'mediaUsagePermission'}
                  title="We love taking photos"
                  subTitle="Tick this box to give Yayem permission to use photos or videos of you in future marketing materials."
                  onChange={(isEnabled) =>
                    formik.setFieldValue('mediaUsagePermission', isEnabled)
                  }
                  defaultValue={formik.values.mediaUsagePermission || true}
                  className="dietry-checkbox"
                />
              </div>
            )}
            <Button type={'submit'} text={'Next'} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
