import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Header, Skeleton } from 'components';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authActions } from 'redux/reducers/auth';
import { checkInActions } from 'redux/reducers/checkIn';
import { profileActions } from 'redux/reducers/profile';
import { selectReservationData } from 'redux/selectors/checkIn';

type QueryParams = {
  reservationId: string;
};

const SuccessScreen = (): JSX.Element => {
  const { reservationId } = useParams<QueryParams>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const checkInData = useAppSelector(selectReservationData);
  const createdAt = checkInData?.checkinDate;
  const invitedBy = checkInData?.invitedBy?.profile;
  const date = createdAt ? moment(createdAt).format('ddd DD MMM | HH:mm') : '';

  useEffect(() => {
    if (reservationId) {
      dispatch(checkInActions.getReservationById(reservationId));
    }
    if (!checkInData && !reservationId) {
      navigate('/');
    }
  }, [reservationId]);

  const handleLogOut = () => {
    navigate('/login');
    dispatch(authActions.reset());
    dispatch(profileActions.reset());
    dispatch(checkInActions.reset());
  };

  return (
    <>
      <Header hasLogo withBackArrow={false} className="success-header" />
      <div className="page success-page">
        <div className="container">
          {date?.length > 0 ? (
            <div className="date-wrap">
              <span className="date dia-title">{date}</span>
              <span className="place dia-title">
                {checkInData?.place?.name}
              </span>
            </div>
          ) : (
            <Skeleton className="success-date-skeleton" />
          )}
          <div className="name-wrap">
            <span className="beaufortPro-lg">Welcome,</span>
            <span className="beaufortPro-lg">
              {checkInData?.user?.profile?.firstName || ''}!
            </span>
          </div>
          {invitedBy && (
            <span className="dia-title invited-by">
              Hosted by {invitedBy?.firstName} {invitedBy?.lastName}
            </span>
          )}
          {checkInData?.experienceOnly && (
            <span className="dia-title invited-by">event access only</span>
          )}
          <Button
            onClick={handleLogOut}
            className="success-btn"
            type="button"
            text={'Close'}
          />
        </div>
      </div>
    </>
  );
};

export default SuccessScreen;
