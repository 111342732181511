import React from 'react';

interface Props {
  className?: string;
  children?: any;
}

const Skeleton: React.FC<Props> = ({ children, className }) => {
  const skeletonClassName = className ?? '';
  return <div className={`skeleton-wrap ${skeletonClassName}`}>{children}</div>;
};

export default Skeleton;
